// util helper methods go here... 

import docTypes from './json/docTypes.json';
import { stepMethods } from './stepMethods';
//import insuredSteps from './json/insuredSteps.json';

//import { emailTemplate } from './commsTemplates/EmailTemplate'; // Adjust the path accordingly

export const utilMethods = {
  
  handleInput: (e, setInputs, setErr, id) => {
    const {name, value} = e.target;
    //console.log('da', name, value, setErr, id);
    const fVal = (name === 'phone') ? utilMethods.formatTypingPhone(value) : value;
    (id) ? 
      setInputs(name, fVal, id) : 
      setInputs(prev => ({...prev, [name]: fVal}));
    // reset error
    setErr && setErr(prev => ({...prev, [name]: ''}));
  },

  computeNet: (sales, salesTax) => {
      const salesNumber = utilMethods.removeCurrencyFormatting(sales);
      const salesTaxNumber = utilMethods.removeCurrencyFormatting(salesTax);
      const net = salesNumber - salesTaxNumber;
      return utilMethods.formatCurrency(net.toString());
  },

  updateInsuredCategoryTotals: (insuredCategories=[]) => {

    const tmpCats = [...insuredCategories];
    const updatedCategories = tmpCats.map((c) => {
      let updatedCategory = {...c};
      //updatedCategory.total = utilMethods.computeTotal(updatedCategory.sales, c.salesTax);
      return updatedCategory;
    });
    return updatedCategories;
  },

/*
  updateInsuredCategoriesUseNet: (insuredCategories, useNetSales) => {

    const tmpCats = [...insuredCategories];
    const updatedCategories = tmpCats.map((c) => {
      let updatedCategory = {...c};
      // user wants to remove a category
      if (useNetSales) {
        const doNotFormatDecimals = true;
        const tmpSalesNum = utilMethods.removeCurrencyFormatting(c.sales);
        const tmpSalesTax = utilMethods.removeCurrencyFormatting(c.salesTax);
        const newSales = utilMethods.formatCurrency((tmpSalesNum - tmpSalesTax).toString(), doNotFormatDecimals) // user got it wrong, and provided gross sales as net sales, so we have to subtract the taxes
        updatedCategory.sales = newSales;
        updatedCategory.total = utilMethods.computeTotal(newSales, c.salesTax);
      } else {
        //const tmpTotalNum = utilMethods.removeCurrencyFormatting(c.total);
        //const tmpSalesTax = utilMethods.removeCurrencyFormatting(c.salesTax);
        //const newSales = utilMethods.formatCurrency(tmpTotalNum).toString()) // revert back
        updatedCategory.sales = updatedCategory.total;
        updatedCategory.total = utilMethods.computeTotal(updatedCategory.sales, c.salesTax);
      }

      return updatedCategory;
     
    });
    return updatedCategories;
  },
  */


  updateInsuredCategories: (insuredCategories, name, value, id) => {

    console.log('yeah now', name, value, id)
    const tmpCats = [...insuredCategories];
    const updatedCategories = tmpCats.map((c) => {
      if (c.id === id) {
        let updatedCategory = {};
        // user wants to remove a category
        if (name === 'selected') {
          updatedCategory = {...c,
            selected: false,
            sales: "0",
            salesTax: "0",
            classCode: '',
            net: '0',
          }
        } else {
          const doNotFormatDecimals = true;
          updatedCategory = {...c,
            selected: true,
            [name]: utilMethods.formatCurrency(value, doNotFormatDecimals), 
            net: utilMethods.computeNet(
              (name === 'sales' ? value : c.sales), 
              (name === 'salesTax' ? value : c.salesTax), 
            ),
          }
        }
        return updatedCategory;
      }
      return c;
    });
    console.log('yeah now2', name, value, id, updatedCategories)
    return updatedCategories;
  },

  updateAuditTotals: (cats = [], valTaxes = false, ogTotals=[]) => {
    //console.log('lets go', cats, valTaxes, ogTotals)

    let glTotal=0;
    let llTotal=0;
    let salesTotal=0;
    let salesTaxTotal=0;
    let worksheetTotal=0;

    if (cats) {

      salesTotal = cats.filter(c => c.selected).reduce((acc, current) => {
        const salesValue = utilMethods.removeCurrencyFormatting(current.sales);
        return acc + salesValue;
      }, 0);

      salesTaxTotal = cats.filter(c => c.selected).reduce((acc, current) => {
        const salesTaxValue = utilMethods.removeCurrencyFormatting(current.salesTax);
        return acc +salesTaxValue;
      }, 0);
      
      worksheetTotal = cats.filter(c => c.selected).reduce((acc, current) => {
        const netSalesValue = utilMethods.removeCurrencyFormatting(utilMethods.computeNet(current.sales, current.salesTax))
        return acc + netSalesValue;
      }, 0);
      
      glTotal = cats.filter(c => c.selected).reduce((acc, current) => {
        const grossSalesValue = utilMethods.removeCurrencyFormatting(current.sales);
        const netSalesValue = utilMethods.removeCurrencyFormatting(utilMethods.computeNet(current.sales, current.salesTax))
        const catTotal = (valTaxes) ? netSalesValue : grossSalesValue;
        return acc + catTotal;
      }, 0);

      llTotal = cats.filter(c => (c.id.startsWith('alcohol') && c.selected)).reduce((acc, current) => {
        const grossSalesValue = utilMethods.removeCurrencyFormatting(current.sales);
        const netSalesValue = utilMethods.removeCurrencyFormatting(utilMethods.computeNet(current.sales, current.salesTax))
        const catTotal = (valTaxes) ? netSalesValue : grossSalesValue;
        return acc + catTotal;
      }, 0);
      //console.log ('gg, ll', glTotal, llTotal)
    }

    //console.log('nu', glTotal, llTotal, salesTotal, salesTaxTotal)
    const updatedOG = {...ogTotals}
    updatedOG.glTotal = utilMethods.formatCurrency(glTotal.toString());
    updatedOG.llTotal = utilMethods.formatCurrency(llTotal.toString());
    updatedOG.salesTotal = utilMethods.formatCurrency(salesTotal.toString());
    updatedOG.salesTaxTotal = utilMethods.formatCurrency(salesTaxTotal.toString());
    updatedOG.worksheetTotal = utilMethods.formatCurrency(worksheetTotal.toString());
    return updatedOG;
  },

  updateAuditActuals: (categories=[], totals) => {
    console.log('hey', categories, totals)
    const tmpCats = [...categories];
    const updatedCategories = tmpCats.map((c) => {
      let updatedCategory = {};
      // user wants to remove a category
      const finalE = (c.policyType === 'General Liability') ? totals.glTotal : totals.llTotal;
      const f = utilMethods.removeCurrencyFormatting(finalE);
      const e = utilMethods.removeCurrencyFormatting(c.estimatedExposure);
      let diff = 1;

      if (f===0 && e===0) {
        diff = 0;
      } else if (f===0) {
        diff = -1;
      } else if (e===0) {
        diff = 1;
      } else {
        diff = (f-e)/e;
      }
      
      console.log('E Display, eNum, F Display, fNum, %', c.estimatedExposure, e, finalE, f, diff);

      const newId = (Math.floor(Math.random() * 90000) + 10000).toString(); // add ID

      updatedCategory = {...c,
        finalExposure: finalE, 
        diff: utilMethods.formatPercent(diff),
        id: c.id || newId, // if this is not set
      }
       
      return updatedCategory;
    });
    return updatedCategories;
  },

  

  customLog: (message) => {
    if (process.env.REACT_APP_DEBUG === 'dev') {
        console.log(message);
    }
  },

  getPhone: (email) => {
    switch (email) {
      case "ilya.mezheritsky@gmail.com":
        return '9739454946';
      case "dbratshpis@gmail.com":
        return '6466781439'
      default: 
        return '9739454946';
    }
  },

  getDebugAddress: (email) => {
    switch (email) {
      case "ilya.mezheritsky@gmail.com":
        return {
          name: 'Ilya Mezheritsky, Co',
          address_line1: '363 Bond St',
          address_line2: '1006',
          address_city: 'Brooklyn',
          address_state: 'NY',
          address_zip: '11231'
        }
      case "dbratshpis@gmail.com":
        return {
          name: 'Dan Bratshpis, Co',
          address_line1: '17 Hemlock Dr',
          address_line2: '',
          address_city: 'Glen Head',
          address_state: 'NY',
          address_zip: '11545'
        }
      default: 
        return {
          name: 'Ilya Mezheritsky, Co',
          address_line1: '363 Bond St',
          address_line2: '1006',
          address_city: 'Brooklyn',
          address_state: 'NY',
          address_zip: '11231'
        }
    }
  },
/*
  getDate: (dateFromFB = '') => {
    let d = new Date();
    if (dateFromFB instanceof Date) {
      d = new Date(dateFromFB);
    } else {
      d = new Date(dateFromFB.toDate());
    }
   
    return d;
 
  },
  */

  getFirebaseDate: (dStr) => {
    if (dStr==='') return dStr;
    let d;
    if (dStr instanceof Date) {
      d = new Date(dStr); // Handle Date objects directly
    } else if (typeof dStr === 'object' && dStr.seconds !== undefined) {
      // Handle objects with "seconds" property (assuming seconds since epoch)
      d = new Date(dStr.seconds * 1000); // Convert seconds to milliseconds
    } else if (typeof dStr === 'string' && dStr !== '') {
      // Handle strings in 'MM/DD/YYYY' format
      const parts = dStr.split('/');
      d = new Date(parseInt(parts[2], 10), parseInt(parts[0], 10) - 1, parseInt(parts[1], 10));
      //console.log('HERE', dStr, d);
    } else {
      // Handle other input formats (optional: add logic for parsing strings)
      d = new Date(); // Or throw an error if parsing strings is not supported
    }
    return d;
  },

  getSubmitDate: (history) => {
    const hSub = history.filter(h => h.op === 'Submitted')[0]
        //console.log('HISTORY', history, hSub)

    return hSub ? utilMethods.formatDate(hSub.date) : '';
  },

  formatDate: (dateStr, full) => {
    //console.log("dateStr", dateStr);
    
    const d = utilMethods.getFirebaseDate(dateStr);
    //console.log("FORMAT DATE (dateStr, d)", dateStr, d);

    if (d) { // Only proceed if a valid date object was obtained
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = String(d.getFullYear()).slice(-2);
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const seconds = String(d.getSeconds()).padStart(2, '0');

      return (full)
        ? `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`
        : `${month}/${day}/${year}`;
    }
    
  },

  formatTimestamp: (ts) => {

    if (!ts) {
      return "Date not available"; // Handle cases where dateCompleted is missing
    }

    const seconds = ts.seconds;
    const nanoseconds = ts.nanoseconds;

    if (typeof seconds !== 'number') {
          return "Invalid date format: Seconds must be a number."
    }

    const milliseconds = seconds * 1000 + nanoseconds / 1000000; // Convert to milliseconds
    const date = new Date(milliseconds);

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (0-indexed)
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(2); // Get last two digits of year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}`;

  },

  roundDownDates: (dateStr) => {
    
    const d = utilMethods.getFirebaseDate(dateStr);
    const rdd = new Date(d.getFullYear(), d.getMonth(), 1);
    return utilMethods.formatDate(rdd, false);
  },

  getLastDayOfPreviousMonth: (dateStr) => {
    const d = utilMethods.getFirebaseDate(dateStr);
    const rdd = new Date(d.getFullYear(), d.getMonth(), 1);
    rdd.setDate(rdd.getDate() - 1);
    return utilMethods.formatDate(rdd, false);
  },

  formatPhone: (phoneNumber = '') => {
   // Remove non-numeric characters except for leading digits (potential country code)
    
    // Remove non-numeric characters
    const digits = phoneNumber.replace(/[^\d]/g, '');

    // Check for valid length (10 or 11 digits)
    if (digits.length !== 10 && digits.length !== 11) {
      return ''; // Return empty string for invalid input
    }

    const noCountryDigits =  (digits.length === 11) ? digits.slice(1,11) : digits;

    // Extract area code (if present)
    const finalPhone = `${noCountryDigits.slice(0,3)}.${noCountryDigits.slice(3,6)}.${noCountryDigits.slice(6-10)}`;
    return finalPhone;
  },

  formatTypingPhone: (phoneNumber = '') => {
    //console.log(phoneNumber)
    let formattedValue = '';
    const digits = phoneNumber.replace(/\D/g, '');

    // Loop through each character and insert separators as needed
    for (let i = 0; i < digits.length; i++) {
      formattedValue += digits[i];
      if (i === 2 || i === 5) {
        formattedValue += '.';
      }
    }
    return formattedValue;
  },

  formatPercent: (value = 0) => {
    console.log('formatP', value);
    if (isNaN(value)) {
      return "NaN%";
    }
/*
    // Handle cases where the absolute value of the number is less than 0.1
    if (Math.abs(value) < 0.1) {
      return "0.0%";
    }
*/

    // Use toFixed to limit to 1 decimal place and multiply by 100 for percentage
    const formattedP = (value * 100).toFixed(1) + "%";
    console.log("formattedP", value, formattedP);
    return formattedP;
  },
/*
  formatCurrency: (amount, locale = 'en-US', options = { style: 'currency', currency: 'USD' }) => {
    
    const cleanValue = amount.replace(/[^0-9]/g, '');

    if (cleanValue === '') {
      return ''; // Handle empty input
    }

    // Format with no decimals and commas for thousands
    const formattedValue = parseInt(cleanValue, 10).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    // Remove trailing ".00" (if added by locale formatting)
    return formattedValue.replace(/\.00$/, '');
  },
  */

  formatCurrency: (amount, notTotal) => {
    // Remove all non-numeric characters except '.' and '-' (handle negative values)
    //const cleanValue = amount.replace(/[^0-9\-.]/g, '');
    //const cleanValue = amount.replace(/[^0-9]/g, '');
    if (!amount) return null;
    const cleanValue = amount.replace(/[^0-9.]/g, '');

    if (cleanValue === '') {
      return ''; // Handle empty input
    }

    const intPart = cleanValue.split(".")[0];
    const trimZeroIntPart = (intPart.startsWith('0') && intPart.length>1) ? intPart.slice(1) : intPart;
    let decimalPart = cleanValue.includes('.') ? ('.'+cleanValue.split(".")[1].slice(0,2)) : '';
    if (!notTotal && decimalPart.length === 2) decimalPart += "0";
    const intPartFormatted = trimZeroIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas every 3 digits

    //const formattedInt = parseInt(intPart).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    return `$${intPartFormatted}${decimalPart}`;
    
  },

  removeCurrencyFormatting: (salesString) => {
  // Remove leading/trailing whitespace and dollar sign
    //console.log("salesString", salesString)
    if (!salesString) return null;

    let trimmedStr = salesString.trim();
    if (trimmedStr.startsWith('$')) trimmedStr = trimmedStr.slice(1);

    // Handle empty strings
    if (!trimmedStr) {
      return 0; // Replace empty string1 with 0
    }

    const number = parseFloat(trimmedStr.replace(/,/g, ''));
    return number;
  },

  formatState: (str = '') => {
    const returnStr = str.toUpperCase();
    const USStates = [ // Replace with your state data source
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
      'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
      'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
      'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
      'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
    ];

    if (str.length === 1) {
      return (USStates.some(s => s.includes(returnStr))) ? returnStr : '';
    } else {
      return (USStates.some(s => s.includes(returnStr))) ? returnStr : 
        utilMethods.formatState(returnStr.slice(0,1));
    }
  },

  objectToString: (obj) => {
    const fieldNames = Object.keys(obj); // Get all field names
    const entries = fieldNames.map(field => `${field}: ${obj[field]}`); // Create key-value pairs with separator
    return `${entries.join(' - ')}`; // Join entries with separator and prefix
  },

  diffDates: (t1, t2 = { seconds: Math.floor(Date.now() / 1000), nanoseconds: 0 }) => {
    if (!t1) return '-';
    const diffInSeconds = Math.abs(t2.seconds - t1.seconds);
    const diffInNanoseconds = Math.abs(t2.nanoseconds - t1.nanoseconds);
    const diffInMillis = diffInSeconds * 1000 + Math.floor(diffInNanoseconds / 1000000);

    const days = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMillis % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMillis % (1000 * 60)) / 1000);

    if (days > 0) {
        return (days < 10 ? `0${days} d` : `${days} d`);
    } else if (hours > 0) {
        return (hours < 10 ? `0${hours} h` : `${hours} h`);
    } else if (minutes > 0) {
        return (minutes < 10 ? `0${minutes} m` : `${minutes} m`);
    } else {
        return (seconds < 10 ? `0${seconds} s` : `${seconds} s`);
    }

    /* full
    if (days > 0) {
        return days + " day" + (days > 1 ? "s" : "");
    } else if (hours > 0) {
        return hours + " hour" + (hours > 1 ? "s" : "");
    } else if (minutes > 0) {
        return minutes + " minute" + (minutes > 1 ? "s" : "");
    } else {
        return seconds + " second" + (seconds > 1 ? "s" : "");
    }
    */
  },

  timeSince: (dateStr) => {
    let d = new Date();
    if (dateStr) {
      if (dateStr instanceof Date) {
        d = new Date(dateStr);
      } else {
        d = new Date(dateStr.toDate());
      }
    }

    var seconds = Math.floor((new Date() - d) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }

    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }

    return interval + ' ' + intervalType + ' ago';
  },

  genRandom: (length = 6) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  },

  subUser: (u) => {
    const system = ['AuditCake', 'SendGrid']
    const auditor = ['Dan Bratshpis', 'Ilya Mez', 'ilya@']
    return system.includes(u) ? 'System' : (auditor.includes(u) ? 'Auditor' : u);
  },

  getMessagesFromHistory: (historyArray) => {
    const mArray = historyArray.map((hist) => {
      // so far all message arrays only have 1 id
      return hist.messages[0].id
    })
    return [...new Set(mArray)]
  },

  getTTC: (historyArray) => {
    //console.log("hArray", historyArray);
    //const dArray = utilMethods.sortByDate(historyArray, true, 'date');
    //const dateDiff = utilMethods.dateDiff();
    const t1 = historyArray[0].date;
    const t2 = historyArray[historyArray.length-1].date;
    const diff = utilMethods.diffDates(t1, t2);
    console.log('DATES:', diff);
    
    return diff;
  },

  getTotals: (ic) => {
    if (ic) {
      const cats = ic.filter(c => c.selected) || [];

      //console.log("cats", cats);
      const totalSales = cats && cats.reduce((acc, current) => {
        const salesValue = utilMethods.removeCurrencyFormatting(current.sales);
        
        return acc + salesValue;
      }, 0);

      const totalSalesTax = cats && cats.reduce((acc, current) => {
        const salesTaxValue = utilMethods.removeCurrencyFormatting(current.salesTax);
        return acc +salesTaxValue;
      }, 0);
/*
      const total = cats && cats.reduce((acc, current) => {
        const salesValue = utilMethods.removeCurrencyFormatting(current.sales);
        const salesTaxValue = utilMethods.removeCurrencyFormatting(current.salesTax);

        return acc + salesValue+salesTaxValue;
      }, 0);
    */

      const netTotal = cats && cats.reduce((acc, current) => {
        const salesValue = utilMethods.removeCurrencyFormatting(current.sales);
        const salesTaxValue = utilMethods.removeCurrencyFormatting(current.salesTax);

        return acc + (salesValue-salesTaxValue);
      }, 0);

      const percent = 1;
      return {
        totalSales: totalSales,
        totalSalesTax: totalSalesTax,
        netTotal: netTotal,
        percent: percent,
      }
    } else {
      return {
        totalSales: 0,
        totalSalesTax: 0,
        netTotal: 0,
        percent: 1,
      }
    }
  },
/*
  getRowTotals: (sales, salesTax) => {
    const salesValue = utilMethods.removeCurrencyFormatting(sales);
    const salesTaxValue = utilMethods.removeCurrencyFormatting(salesTax);

    return salesValue+salesTaxValue;
  },
  */

  getRowPercent: (ic, sales, salesTax) => {
    const rTotal = utilMethods.removeCurrencyFormatting(sales);//utilMethods.getRowTotals(sales, salesTax);
    const feTotal = utilMethods.getTotals(ic).totalSales;
    console.log('getROWpercent', rTotal, feTotal)
    console.log('percent', Number(rTotal / feTotal));

    return Number(rTotal / feTotal);
  },

  getNewAudit: (inputs, existingAudit) => {
    const ac = (Math.floor(Math.random() * 90000) + 10000).toString();
    if (!inputs) {
      inputs = {
        auditCode: ac,
        carrierCompany: '', 
        auditType: '',
        policyType: '',
      }
    }

    const totals = utilMethods.updateAuditTotals(
      existingAudit?.insuredCategories,
      existingAudit?.insuredCategoryTotals?.valTaxes,
      existingAudit?.insuredCategoryTotals
    );
    
    const newAudit = {
      dateAdded: existingAudit?.dateAdded || new Date(),
      auditCode: existingAudit?.auditCode || inputs.auditCode || '',
      carrierCompany: existingAudit?.carrierCompany || inputs.carrierCompany || '',
      auditType: existingAudit?.auditType || inputs.auditType || '',
      policyNumber: existingAudit?.policyNumber || '',
      orderNumber: existingAudit?.orderNumber || '',

      policyStart: utilMethods.formatDate(existingAudit?.policyStart) || '',
      policyEnd: utilMethods.formatDate(existingAudit?.policyEnd) || '',
      auditStart: utilMethods.formatDate(existingAudit?.auditStart) || utilMethods.formatDate(existingAudit?.policyStart) || '',
      auditEnd: utilMethods.formatDate(existingAudit?.auditEnd) || utilMethods.formatDate(existingAudit?.policyEnd) || '',
      auditNoFlyDue: utilMethods.formatDate(existingAudit?.auditNoFlyDue) || '',
      auditDue: utilMethods.formatDate(existingAudit?.auditDue) || '',

      insuredCompany: existingAudit?.insuredCompany || "",
      insuredDbaName: existingAudit?.insuredDbaName || '',
      insuredDescriptionOfOps: existingAudit?.insuredDescriptionOfOps || '',

      insuredLastName: existingAudit?.insuredLastName || "",
      insuredFirstName: existingAudit?.insuredFirstName || "",
      insuredEmail: existingAudit?.insuredEmail || "",
      insuredPhone: existingAudit?.insuredPhone || "",
      insuredCell: existingAudit?.insuredCell || "",

      insuredMailingAddress1: existingAudit?.insuredMailingAddress1 || '',
      insuredMailingAddress2: existingAudit?.insuredMailingAddress2 || '',
      insuredMailingCity: existingAudit?.insuredMailingCity || '',
      insuredMailingState: existingAudit?.insuredMailingState || '',
      insuredMailingZip: existingAudit?.insuredMailingZip || '',

      insuredLocationAddress1: existingAudit?.insuredLocationAddress1 || '',
      insuredLocationAddress2: existingAudit?.insuredLocationAddress2 || '',
      insuredLocationCity: existingAudit?.insuredLocationCity || '',
      insuredLocationState: existingAudit?.insuredLocationState || '',
      insuredLocationZip: existingAudit?.insuredLocationZip || '',
      
      //insuredCategories: existingAudit?.insuredCategories,
      insuredCategories: utilMethods.updateInsuredCategoryTotals(existingAudit?.insuredCategories),
      insuredCategoryTotals: totals, // existingAudit?.insuredCategoryTotals || totals used to do this, but there was a bug where if they are set to 0 initially, they won't overwrite as a result with real totals

      delegateLastName: existingAudit?.delegateLastName || "",
      delegateFirstName: existingAudit?.delegateFirstName || "",
      delegateEmail: existingAudit?.delegateEmail || "",
      delegatePhone: existingAudit?.delegatePhone || "",
      delegateRelationship: existingAudit?.delegateRelationship || "",
      delegateMessage: existingAudit?.delegateMessage || "",

      agentCompany: existingAudit?.agentCompany || "",
      agentMailingAddress1: existingAudit?.agentMailingAddress1 || '',
      agentMailingAddress2: existingAudit?.agentMailingAddress2 || '',
      agentMailingCity: existingAudit?.agentMailingCity || '',
      agentMailingState: existingAudit?.agentMailingState || '',
      agentMailingZip: existingAudit?.agentMailingZip || '',
      agentLastName: existingAudit?.agentLastName || "",
      agentFirstName: existingAudit?.agentFirstName || "",
      agentEmail: existingAudit?.agentEmail || "",
      agentPhone: existingAudit?.agentPhone || "",
      agentCell: existingAudit?.agentCell || "",

      agentProvidedFirstName: existingAudit?.agentProvidedFirstName || '',
      agentProvidedLastName: existingAudit?.agentProvidedLastName || '',
      agentProvidedEmail: existingAudit?.agentProvidedEmail || '',
      agentProvidedPhone: utilMethods.formatPhone(existingAudit?.agentProvidedPhone) || '',

      agentInsuredFirstName: existingAudit?.agentInsuredFirstName || '',
      agentInsuredLastName: existingAudit?.agentInsuredLastName || '',
      agentInsuredEmail: existingAudit?.agentInsuredEmail || '',
      agentInsuredPhone: utilMethods.formatPhone(existingAudit?.agentInsuredPhone) || '',
  

      //keyQuestions: existingAudit?.keyQuestions || '',
      //recordsAudited: existingAudit?.recordsAudited || [],
      //steps: existingAudit?.steps || [],
      consolidatedId: existingAudit?.consolidatedId || '',
      history: existingAudit?.history || [],
      pdfHistory: existingAudit?.pdfHistory || null,
      notes: existingAudit?.notes || [],
      submitted: existingAudit?.submitted || false,
      anythingElse: existingAudit?.anythingElse || '',
      sliceID: existingAudit?.sliceID || '9999',
      xmlFilePath: existingAudit?.xmlFilePath || '',

      cost: existingAudit?.cost || '',
      outcome: existingAudit?.outcome || '',
      pdfFileName: existingAudit?.pdfFileName || '',
      xmlFileName: existingAudit?.xmlFileName || '',
      bakeState: existingAudit?.bakeState || '',
    };    

    let newId='';
    if (existingAudit?.categories) {
      const updatedCategories = utilMethods.updateAuditActuals(existingAudit?.categories, totals);
      newAudit.categories = updatedCategories;
    } else {
      newId = (Math.floor(Math.random() * 90000) + 10000).toString();
          
      newAudit.categories = [
        {
          id: newId,
          classCode: 'NEW_'+newId,
          subLine: '',
          classDescription: '', //classification
          policyType: inputs.policyType || '', //coverage part
          exposureType: '', //basis
          estimatedExposure: '', //premium basis
          notes: '',
          finalExposure: '',
          diff: '',
        }
      ];
    }

    return newAudit;
  },

  convertUSLIAudit: (newOrder={}, debug, uploadId, xmlUrl, xmlFilePath) => {
    //const ac = (Math.floor(Math.random() * 90000) + 10000).toString();
    
    const totals = utilMethods.updateAuditTotals(
      [],
      false,
      [],
    );

    console.log("totals",totals)

    const newAudit = {
      id: uploadId, // this will get deleted during create
      xmlUrl: xmlUrl,
      xmlFilePath: xmlFilePath,
      dateAdded: new Date(),
      auditCode: newOrder?.Order_Number || '',
      orderNumber: newOrder?.Order_Number || '',
      carrierCompany: 'USLI',
      auditType: newOrder?.Report_Type || '',
      policyNumber: newOrder?.Policy_Number || '',

      policyStart: utilMethods.formatDate(newOrder?.Policy_From) || '',
      policyEnd: utilMethods.formatDate(newOrder?.Policy_To) || '',
      auditStart: utilMethods.formatDate(newOrder?.Policy_From) || '',
      auditEnd: utilMethods.formatDate(newOrder?.Policy_To) || '',
      auditDue: utilMethods.formatDate(newOrder?.auditDue) || '',

      insuredCompany: newOrder?.Insured_Name || "",
      insuredDbaName: newOrder?.Insured_Name2 || '',
      insuredDescriptionOfOps: newOrder?.Business_Desc || '',

      insuredLastName: newOrder?.Insured_Contact || "",
      insuredFirstName: newOrder?.Insured_Contact || "",
      insuredEmail: newOrder?.Insured_ContactEmail || "",
      insuredPhone: utilMethods.formatPhone(newOrder?.Insured_ContactPhone) || "",
/*
not provided in xml
      insuredMailingAddress1: existingAudit?.insuredMailingAddress1 || '',
      insuredMailingAddress2: existingAudit?.insuredMailingAddress2 || '',
      insuredMailingCity: existingAudit?.insuredMailingCity || '',
      insuredMailingState: existingAudit?.insuredMailingState || '',
      insuredMailingZip: existingAudit?.insuredMailingZip || '',

      insuredLocationAddress1: newOrder?.Insured_Address || '',
      insuredLocationAddress2: newOrder?.Insured_Address2 || '',
      insuredLocationCity: newOrder?.Insured_City || '',
      insuredLocationState: newOrder?.Insured_State || '',
      insuredLocationZip: new
*/
      insuredMailingAddress1: newOrder?.Insured_Address || '',
      insuredMailingAddress2: newOrder?.Insured_Address2 || '',
      insuredMailingCity: newOrder?.Insured_City || '',
      insuredMailingState: newOrder?.Insured_State || '',
      insuredMailingZip: newOrder?.Insured_Zipcode || '',

      insuredCategories: [],
      //insuredCategories: utilMethods.updateInsuredCategoryTotals(existingAudit?.insuredCategories),
      insuredCategoryTotals: totals, // existingAudit?.insuredCategoryTotals || totals used to do this, but there was a bug where if they are set to 0 initially, they won't overwrite as a result with real totals

      /*agentCompany: existingAudit?.agentCompany || "",
      agentMailingAddress1: existingAudit?.agentMailingAddress1 || '',
      agentMailingAddress2: existingAudit?.agentMailingAddress2 || '',
      agentMailingCity: existingAudit?.agentMailingCity || '',
      agentMailingState: existingAudit?.agentMailingState || '',
      agentMailingZip: existingAudit?.agentMailingZip || '',
      agentLastName: existingAudit?.agentLastName || "",
      agentFirstName: existingAudit?.agentFirstName || "",
      agentEmail: existingAudit?.agentEmail || "",
      agentPhone: existingAudit?.agentPhone || "",
      agentCell: existingAudit?.agentCell || "",


      agentProvidedFirstName: existingAudit?.agentProvidedFirstName || '',
      agentProvidedLastName: existingAudit?.agentProvidedLastName || '',
      agentProvidedEmail: existingAudit?.agentProvidedEmail || '',
      agentProvidedPhone: utilMethods.formatPhone(existingAudit?.agentProvidedPhone) || '',

      agentInsuredFirstName: existingAudit?.agentInsuredFirstName || '',
      agentInsuredLastName: existingAudit?.agentInsuredLastName || '',
      agentInsuredEmail: existingAudit?.agentInsuredEmail || '',
      agentInsuredPhone: utilMethods.formatPhone(existingAudit?.agentInsuredPhone) || '',
  */

      
      //consolidatedId: existingAudit?.consolidatedId || '',
      history: [],
      notes: [],
      //submitted: existingAudit?.submitted || false,
      anythingElse: '',

    };   

    const cats = newOrder?.Class_Codes?.Class_Code;
    if (cats) {
      const convertedCats = cats.map((c) => {
        const newId = (Math.floor(Math.random() * 90000) + 10000).toString(); // add ID
        const newCat = {
          id: newId,
          classCode: c.Class_Id || '',
          subLine: '',
          classDescription: c.Class_Description || '',
          policyType: '', //coverage part
          exposureType: '', //basis
          estimatedExposure: utilMethods.formatCurrency(c.Premium_Basis) || '', //premium basis
          notes: '',
          finalExposure: '',
          diff: '',
        }
        return newCat;
      })
      const updatedCategories = utilMethods.updateAuditActuals(convertedCats, totals);
      newAudit.categories = updatedCategories;
    } 

    return newAudit;
  },

  getTabsForAudit: (existingAudit={categories: []}) => {
    /*const initTabNames = [
      "Provided", "General", "Dates", "Insured", "Provided Insured", "Agent", "Provided Agent", "Unproductive"
    ];*/

    const initTabNames = [
      "Provided", "General", "Dates", "Insured", "Agent", "Unproductive"
    ];
    /*
    if (existingAudit.categories) {
      for (const t of existingAudit?.categories) {
        if (!initTabNames.includes(t.classCode)) {
          initTabNames.push(t.classCode)
        }
      }
    }
    //console.log('initCat', initTabNames);
    */
    return initTabNames;
  },

  setCats: (audit, cats) => {
    if (!audit.insuredCategories) return cats;
    // prob a way to improve this double loop
    const merged = [];
    for (const initCat of cats) {
      let i = initCat;
      for (const auditCat of audit.insuredCategories) {
        if (initCat.id === auditCat.id) {
          i.selected = auditCat.selected;
          i.sales = auditCat.sales;
          i.salesTax = auditCat.salesTax;
        }
      }
      merged.push(i);
    }
    //console.log('MERGED', merged)
    return merged;
  },

  consolidateUploadedRows: (arr, carrier) => {

    function removeEmptyFields(data) {
      return data.map(obj => {
        // Use Object.fromEntries to create a new object from filtered key-value pairs
        return Object.fromEntries(
          Object.entries(obj).filter(([key]) => !key.startsWith("__EMPTY"))
        );
      });
    }

    // Define a function to handle the else logic
    function handleElseLogic (currentId, output, innerObj, currentObj) {
      //console.log("currentId", currentObj)
      const rowToUpdate = output.find((row) => row.consolidatedId === currentId);

      rowToUpdate.categories.push(innerObj);

      // also overwrite other fields (e.g. 2nd row had a value instead of first row)
      for (const key in currentObj) {
        // Check if the property has its own value (not inherited)
        console.log("key", key, currentObj[key])
        if (currentObj[key] !== '') {
          //return true; // Non-empty value found
          console.log('FOUND', key, currentObj[key], rowToUpdate[key])
          // TODO: can check if original field has a value, and call out a merge conflict
          rowToUpdate[key] = currentObj[key]
        }
      }

      output = output.filter((row) => row.consolidatedId !== currentId);
      output.push(rowToUpdate);
    };

    const fieldsToMove = ["CoveragePart", "ClassCode", "Classification", "PremiumBasis", "QuickyNotes"];
    const columnForNewPolicy = "PolicyNumber";
    let output = [];
    let currentId = '';

    for (const obj of removeEmptyFields(arr)) {
      let innerObj = {};

      for (const ftm of fieldsToMove) {
        innerObj[ftm] = obj[ftm];
        delete obj[ftm];
      }

      //console.log("obj", obj)

      if (obj[columnForNewPolicy]) {
        currentId = (Math.floor(Math.random() * 90000000000) + 10000000000).toString(); 
        const updatedObject = {...obj, categories: [innerObj], consolidatedId: currentId, carrierCompany: carrier.name};
        output.push(updatedObject);
        //console.log("cur", currentId);

      } else {
         //const rowToUpdate = output.find((row) => row.id === currentId);
         //console.log("cure", currentId);
         handleElseLogic(currentId, output, innerObj, obj);

         //rowToUpdate.audits.push(innerObj);
         //output = output.filter((row) => row.id !== currentId); // Filter out the object with the matching ID
         //output.push(rowToUpdate); // Spread operator to create a new array with the new object

        //let tmpObj = output[currentId];
        //tmpObj.audits.push(innerObj);
        //output[currentId] =tmpObj;
      }

   }
    return output;
  },

  mapAudits: (sheetAudits, mappings, mappingsClasses) => {
    
    function separateName(fullName) {
      // Trim leading/trailing whitespace
      const trimmedName = fullName.trim();

      // Check for empty string
      if (!trimmedName) {
        return { firstName: '', lastName: '' };
      }

      // Split on a space (may not always be reliable)
      const nameParts = trimmedName.split(/\s+/);

      // Handle single name case (e.g., "John")
      if (nameParts.length === 1) {
        return { firstName: nameParts[0], lastName: '' };
      }

      // Handle multiple names (e.g., "John Doe")
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' '); // Join remaining parts for last name

      return { firstName, lastName };
    }

    function separateAddress(fullAddress) {
      // Trim leading/trailing whitespace
      const trimmedAddress = fullAddress.trim();

      // Split on commas
      const addressParts = trimmedAddress.split(',');

      // Handle empty string or single element
      if (!trimmedAddress || addressParts.length < 2) {
        return { locAddress1: '', locAddress2: '', locCity: '', locState: '', locZip: '' };
      }

      // Extract address components based on number of parts
      const numParts = addressParts.length;
      const locAddress1 = addressParts[0];

      if (numParts === 3) {
        const [locCity, ...stateZip] = addressParts.slice(1);
        const [locState, locZip] = stateZip[0].trim().split(' ');
        return { locAddress1, locAddress2: '', locCity, locState, locZip };
      } else if (numParts === 4) {
        const locAddress2 = addressParts[1];
        const [locCity, ...stateZip] = addressParts.slice(2);
        const [locState, locZip] = stateZip[0].trim().split(' ');
        return { locAddress1, locAddress2, locCity, locState, locZip };
      } else {
        // Handle cases with more than 4 parts (return empty strings)
        return { locAddress1: '', locAddress2: '', locCity: '', locState: '', locZip: '' };
      }
    }



    const tAudits = [];
    sheetAudits.forEach(audit => {
      const newA = utilMethods.getNewAudit();

      // transform headers
      Object.keys(audit).forEach(field => {
        if (mappings[field]) {
          //field === 'DueDate' && console.log("what field?", newA, mappings[field].fieldType);

          switch (mappings[field].fieldType) {
            case "date":
              newA[mappings[field].can] = new Date(audit[field])
              break;
            case "multi":
              break
            case "explode":
              console.log("explode", field, audit[field]);
              switch (field) {
                case "AuditContact":
                  const {firstName, lastName} = separateName(audit[field]);
                  //console.log("fL", firstName, lastName, audit[field]);
                  newA.insuredFirstName = firstName;
                  newA.insuredLastName = lastName;
                  break;
                case "RetailerContactName":
                  const agentName = separateName(audit[field]);
                  //console.log("fL", firstName, lastName, audit[field]);
                  newA.agentFirstName = agentName.firstName;
                  newA.agentLastName = agentName.lastName;
                  break;
                case "CustomerContactName":
                  const aName = separateName(audit[field]);
                  //console.log("fL", firstName, lastName, audit[field]);
                  newA.agentFirstName = aName.firstName;
                  newA.agentLastName = aName.lastName;
                  break;
                case "LocationAddress":
                  const {locAddress1, locAddress2, locCity, locState, locZip } = separateAddress(audit[field]);
                  //console.log("loc", locAddress1, locAddress2, locCity, locState, locZip, audit[field]);
                  newA.insuredLocationAddress1 = locAddress1;
                  newA.insuredLocationAddress2 = locAddress2;
                  newA.insuredLocationCity = locCity;
                  newA.insuredLocationState = locState;
                  newA.insuredLocationZip = locZip;
                  break;
                case "MailingAddress":
                  const mail = separateAddress(audit[field]);
                  //console.log("mail", mail, audit[field]);
                  newA.insuredMailingAddress1 = mail.locAddress1;
                  newA.insuredMailingAddress2 = mail.locAddress2;
                  newA.insuredMailingCity = mail.locCity;
                  newA.insuredMailingState = mail.locState;
                  newA.insuredMailingZip = mail.locZip;
                  break;
                default: //string
                  newA[mappings[field].can] = audit[field]
                  break;
              }
              break;
            default: //string
              newA[mappings[field].can] = audit[field]
              break;
          }
        }
      })

      //console.log("auditWhat?", audit)
      // transform categories
      audit.categories.forEach(cat => {
        const newC = {};
        
        // transform headers
        //console.log("cat wiliams", Object.keys(cat));
        Object.keys(cat).forEach(field => {
          //console.log("field what?", field, mappingsClasses, mappingsClasses[field])
          if (mappingsClasses[field]) {
            newC[mappingsClasses[field].can] = cat[field]
          }
        })

        //console.log("new c what?", newC);

        newA.categories.push(newC);
      });

      // remove initial blank default categories
      //console.log('BBBB', newA.categories);

      if (newA.categories.length > 1) {
        //console.log('AAAAAAAAA', newA.categories, newA.categories.filter((cat) => cat.classCode.contains('NEW_')))
        newA.categories = newA.categories.filter((cat) => !cat.classCode.includes('NEW_')); // Filter out the object with the blank classCode
      }
      tAudits.push(newA);
    });
    //console.log("tAudits", tAudits);
    return tAudits;
  },

  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  // TODO: this method may not be used as i am no longer validating at upload stage
  validateUpload: (inputs) => {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const errors =[];
    for (const upload of inputs) {
      if (!validateEmail(upload.insuredEmail)) {
        errors.push(
          {uploadItemId: upload.id, err: `Invalid Email: ${upload.insuredEmail}`}
        )
      }
    }
    console.log("validation errors:", errors)
    return errors;
  },

  sortBy: (arr, field) => {
    const sorted = arr.sort(function(a, b) {
        var x = a[field];
        var y = b[field];
        //console.log(x,y);
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
    //console.log('sortyby', arr, field, sorted)

    return sorted;
  },

  sortByHistory: (arr, op, field) => {
    const sorted = arr.sort(function(a, b) {
        var x = a.history.filter(h => h.op === op)[0];
        var xField = (x) ? x[field] : '';
        var y = b.history.filter(h => h.op === op)[0];
        var yField = (y) ? y[field] : '';
        return ((xField < yField) ? -1 : ((xField > yField) ? 1 : 0));
     });
    console.log('sortybyhistory', arr, op, field, sorted)

    return sorted;
  },

  sortByDate: (arr, asc=false, dateField = "dateAdded") => {
    //console.log('SORT BY DATE', arr, asc, dateField)
    /*const getDate = (dStr) => {
      let d = new Date();
      if (dStr instanceof Date) {
        d = new Date(dStr);
      } else {
        d = new Date(dStr.toDate());
      }
      return d;
    };
    */

    

    let tmpArr = [...arr]; // was causing a bug where it was resorting the original audit and viewAudits objects!
    return tmpArr.sort(function(a, b) {
      const x = utilMethods.getFirebaseDate(a[dateField]);
      const y = utilMethods.getFirebaseDate(b[dateField]);
      //console.log(x, y);
      //return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      return (asc) ? y - x : x - y;  // Equivalent to (dateB.getTime() - dateA.getTime())
    });
    //console.log('sorted', sorted)
    //return sorted;
  },

  sortByKeyOrder: (mappings) =>  {
    return Object.fromEntries(
      Object.entries(mappings)
        .sort(([keyA, valueA], [keyB, valueB]) => valueA.order - valueB.order)
    );
  },

  sortByDocType: (arr, stepType) => {

    // filter out for certain step types
    let newArr = arr;
    if (stepType === 'S5') {
      newArr = arr.filter((obj) => obj.tag === "D3");
    }

    //console.log("arr", arr);
    //console.log("newArr", newArr);

    return newArr.sort(function(a, b) {
        var x = a.tag;
        var y = b.tag;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
  },

  sortByFlags: (arr) => {
    const sortVal = (a) => {
      if (a.bakeState === 'ORDER') return 1;
      if (a.auditDueDays === '30') return 2;
      if (utilMethods.checkFire(a)) return 3;
      return 4;
    }

    let tmpArr = [...arr]; // was causing a bug where it was resorting the original audit and viewAudits objects!
    return tmpArr.sort(function(a, b) {
      const x = sortVal(a);
      const y = sortVal(b)
      return x - y;  
    });
  },

  checkFire: (rowAudit) => {
    if (!rowAudit.insuredEmail && rowAudit.bakeState === 'CONTACTING') {
      return true;
    }
    return false;
  },

  getDocTypes: () => {
    //let myMap = {};
    //docTypes.forEach(obj => myMap[obj.id] = obj.val);
    //return myMap;
    return docTypes;
  },

/*
  getInsuredSteps: () => {
    return insuredSteps;
  },
  */

  guessDoctype: (title) => {
    // Find the docType with the highest similarity score
    let bestMatch = { score: 0, id: '' };
    for (const docType of docTypes) {
      const similarityScore = calculateSimilarity(title, docType.val);
      if (similarityScore > bestMatch.score) {
        bestMatch = { score: similarityScore, id: docType.id };
      }
    }

    return bestMatch.id;

    function calculateSimilarity(title, val) {
      // Strip file extensions from title and val
      const normalizedTitle = stripFileExtension(title).toLowerCase().replace(/\s|\./g, '');
      const normalizedVal = stripFileExtension(val).toLowerCase().replace(/\s|\./g, '');

      // Calculate Levenshtein distance
      const levenshteinDistance = calculateLevenshteinDistance(normalizedTitle, normalizedVal);

      // Calculate substring bonus (if applicable)
      let substringBonus = 0;
      if (normalizedVal.indexOf(normalizedTitle) !== -1) {
        substringBonus = (1 - levenshteinDistance / Math.max(normalizedTitle.length, normalizedVal.length)) * 0.5; // Weight (adjust as needed)
      }

      // Combine similarity and bonus (adjust weights as needed)
      const similarity = 1 - (levenshteinDistance / Math.max(normalizedTitle.length, normalizedVal.length)) + substringBonus;

      return similarity;
    }

    // Helper function to strip file extensions
    function stripFileExtension(str) {
      return str.replace(/\.[^.]+$/, '');
    }

    // Optimized Levenshtein distance calculation
    function calculateLevenshteinDistance(title, val) {
      const len1 = title.length;
      const len2 = val.length;
      const d = new Array(len1 + 1).fill(null).map(() => new Array(len2 + 1).fill(0));

      for (let i = 0; i <= len1; ++i) {
        d[i][0] = i;
      }
      for (let j = 0; j <= len2; ++j) {
        d[0][j] = j;
      }

      for (let i = 1; i <= len1; ++i) {
        for (let j = 1; j <= len2; ++j) {
          const cost = title[i - 1] !== val[j - 1] ? 1 : 0;
          d[i][j] = Math.min(
            d[i - 1][j] + 1,
            d[i][j - 1] + 1,
            d[i - 1][j - 1] + cost
          );
        }
      }

      return d[len1][len2];
    }

  },

  guessCanHeader: (inputHeader, candidates) => {
    // Find the docType with the highest similarity score
    let bestMatch = { score: 0, val: '' };
    for (const h of candidates) {
      const similarityScore = calculateSimilarity(inputHeader, h);
      if (similarityScore > bestMatch.score) {
        bestMatch = { score: similarityScore, val: h };
      }
    }

    return bestMatch.val;

    function calculateSimilarity(inputHeader, candidateHeader) {
      // Strip file extensions from title and val
      const normalizedTitle = inputHeader.toLowerCase().replace(/\s|\./g, '');
      const normalizedVal = candidateHeader.toLowerCase().replace(/\s|\./g, '');

      // Calculate Levenshtein distance
      const levenshteinDistance = calculateLevenshteinDistance(normalizedTitle, normalizedVal);

      // Calculate substring bonus (if applicable)
      let substringBonus = 0;
      if (normalizedVal.indexOf(normalizedTitle) !== -1) {
        substringBonus = (1 - levenshteinDistance / Math.max(normalizedTitle.length, normalizedVal.length)) * 0.5; // Weight (adjust as needed)
      }

      // Combine similarity and bonus (adjust weights as needed)
      const similarity = 1 - (levenshteinDistance / Math.max(normalizedTitle.length, normalizedVal.length)) + substringBonus;

      return similarity;
    }

    // Optimized Levenshtein distance calculation
    function calculateLevenshteinDistance(title, val) {
      const len1 = title.length;
      const len2 = val.length;
      const d = new Array(len1 + 1).fill(null).map(() => new Array(len2 + 1).fill(0));

      for (let i = 0; i <= len1; ++i) {
        d[i][0] = i;
      }
      for (let j = 0; j <= len2; ++j) {
        d[0][j] = j;
      }

      for (let i = 1; i <= len1; ++i) {
        for (let j = 1; j <= len2; ++j) {
          const cost = title[i - 1] !== val[j - 1] ? 1 : 0;
          d[i][j] = Math.min(
            d[i - 1][j] + 1,
            d[i][j - 1] + 1,
            d[i - 1][j - 1] + cost
          );
        }
      }

      return d[len1][len2];
    }

  },

/*
  getOutreachEmail: (inputs) => {
    return emailTemplate(inputs); 
  },
  */

  getAuditStatus: (history, atts, bakeState) => {
    //const sentToCarrier = history.some((h) => h.op?.toLowerCase() === 'sent to carrier')
    const cancelled = bakeState === 'CANCELLED'
    const invoiced = bakeState === 'INVOICED'
    const sentToCarrier = bakeState === 'SENT_TO_CARRIER'
    const frc = bakeState === 'FINAL_REVIEW_COMPLETE'

    const prod = (bakeState === 'PRODUCTIVE') //!invoiced && !sentToCarrier && history?.some((h) => h.op?.toLowerCase() === 'final summary')
    const nonprod = (bakeState === 'NONPRODUCTIVE' || bakeState === 'UNPRODUCTIVE')//!invoiced && !sentToCarrier && history?.some((h) => h.op?.toLowerCase() === 'final summary')

    const wrongDocs = bakeState === 'WRONG_DOCS'//!sentToCarrier && !invoiced && !danFinalReview && atts?.some((att) => att.sad) && atts?.length>0;

    const submitted = bakeState === 'SUBMITTED' // !wrongDocs && !sentToCarrier && !invoiced && !finalReview && history?.some((h) => h.op?.toLowerCase() === 'submitted') && atts?.length>0;
    const skippedDocs = bakeState === 'SKIPPED'//!wrongDocs && !sentToCarrier && !invoiced && !finalReview && history?.some((h) => h.op?.toLowerCase() === 'submitted') && atts?.length===0;
    
    const heartbeat = bakeState === 'HEARTBEAT' // history.some((h) => h.op?.toLowerCase() === 'opened audit') &&  !history.some((h) => h.op?.toLowerCase() === 'submitted');
    const worksheet = bakeState === 'WORKSHEET' // history.some((h) => h.op?.toLowerCase() === 'opened audit') &&  !history.some((h) => h.op?.toLowerCase() === 'submitted');
    const paused = bakeState === 'PAUSED' // history.some((h) => h.op?.toLowerCase() === 'opened audit') &&  !history.some((h) => h.op?.toLowerCase() === 'submitted');

    /*const emailOpened =  history.some((h) => {
      return ( h.op?.toLowerCase()?.startsWith('email open (emailed insured') || 
        h.op?.toLowerCase()?.startsWith('email open (insured_'))
       && !history.some((h) => h.op?.toLowerCase() === 'opened audit')
    });
    */
    
    const contacting = bakeState === 'CONTACTING' // history.length > 1 && !history.some((h) => h.op?.toLowerCase() === 'opened audit') && !history.some((h) => h.op?.toLowerCase()?.startsWith('email open (emailed insured'));
    const ready = bakeState === 'READY'
    const order = bakeState === 'ORDER'

    return {
      'order': order,
      'ready': ready,
      'contacting': contacting,
      'heartbeat': heartbeat,
      'skipped docs': skippedDocs,
      'submitted': submitted,
      'wrong docs': wrongDocs,
      'productive': prod,
      'nonproductive': nonprod,
      'final review complete': frc,
      'sent to carrier': sentToCarrier,
      'invoiced': invoiced,
      'cancelled': cancelled,
      worksheet, 
      paused,
    }
  },

  getStatus: (vAudit, i) => {
    const style = 'material-symbols-outlined of-icon';
    switch (i) {
      case 0: 
        const contacted = (h) => h.op.includes('Emailed') || h.op.includes('Called') || h.op.includes('Texted');
        return vAudit.history.some(contacted) ? style + ' of-status-done' : style + ' of-status-incomplete';
      case 1: 
        const heart = (h) => h.op === 'Opened Audit';
        return vAudit.history.some(heart) ? style + ' of-status-done' : style + ' of-status-incomplete';
      /*case 2: 
        const progress = (h) => h.op.includes('Selected');
        return vAudit.history.some(progress) ? style + ' of-status-done' : style + ' of-status-incomplete';
      */
      case 2: 
        const submitted = (h) => h.op.includes('Submitted');
        return vAudit.history.some(submitted) ? style + ' of-status-done' : style + ' of-status-incomplete';
      default:
        return style;
      }
  },
/* repurposed
  getActivity: (audits) => {
    //const bakery = audits.map(a => a.history.map(h => h.op));
    const activity = [...new Set(audits.flatMap(a => a.history?.map(h => h.op)))].sort();
    return activity;
  },
*/

  getBakery: (audits) => {
    //const bakery = audits.map(a => a.history.map(h => h.op));
    const bakery = stepMethods.getStepTypes().map(s => s.id);
    //console.log('BAKERY', bakery);
    //[...new Set(audits.flatMap(a => a.history.map(h => h.op)))].sort();
    return bakery;
  },

  getAuditDates: (aArr) => {
    const uniqueDates = new Set(); // Use Set for efficient storage of unique values

    for (const item of aArr) {
      for (const h of item?.history) {
        // Check if "dateCompleted" exists and is a valid date
        if (h.hasOwnProperty('date')) {
          // Convert date to a string in YYYY-MM-DD format for consistent comparison
          const formattedDate = utilMethods.formatDate(h.date, false);
          uniqueDates.add(formattedDate); // Add the formatted date to the Set
        }
      }
    }

    return Array.from(uniqueDates).sort();
  },

  getKeyQuestions: () => {
    const q = {
      salesTax: 'Do you collect sales tax?',
      newProducts: 'Have you introduced new products?',
      
      nameChange: 'Has your business name changed?',
      entityTypeChange: 'Has your entity type changed?',
      locationsChange: 'Locations added or removed?',
      mailingAddressChange: 'Mailing address changed?',
      ownerChange: 'Officers/Owners changed?',
      
      opsChange: 'Have your operations changed?',
      
      releaseToAgent: 'Can we release your audit results to your agent?',
    }
    return q;
  },

}